const HalftoneElement = document.getElementById("profile");
const landing = document.querySelector(".landing--word");
const word = landing.querySelector(".word");
const definition = landing.querySelector("p");

const windowInnerHeight = window.innerHeight;

// So we want to detect if a element is in the middle
// of the screen, with that we want to trigger some parallax
// so that the scrolling, with some margins can give animation to an element
// +-------------------+
// | The page          |
// +-------------------+
// |                   |
// |  The view port    |
// |                   |
// | +---------------+ |
// +-+---------------+-+
// | |               | |
// | |  Our element  | |
// | |               | |
// | +---------------+ |
// |                   |
// +-------------------+
//
// The page with might be irrelevant let's explore the options
// Viewport can be rapresented by
// top: current top scrolling position
// bottom: the top + the height of the viewport
//
// Element has the following properties
// top: from getBoundingClientRect().top the current position relative to viewport
// bottom: from getBoundingClientRect().bottom the current position relative to viewport
//
// So lets plot a scenario
// We have a viewport of { top: 10, bottom: 20 } The height of viewport is 10.
// Then we have our element at { top: 20, bottom: 30  } The height of the element is same as the viewport.
// We then want to have a blur of 5px and when the element is at the center we want to have of 0px
//
// The first thing is to make sure that the element is in the viewport
// if (bottom >= top ) is visible from bottom
// then we now that the blur should be 5px
// Although when viewport becomes { top: 15, bottom: 25 }
// and the elements becomes { top: 0, bottom: 10 } the blur should be 0px
// To invert this calculation we could do
// (heightOfTheWindow - (topOfTheElement+heightOfTheWindow)) so in our case if is visible 10-10 but if
// not in window

function getStep(input = 0, total = 10, from = 0, to = 10, step = 1) {
  const percentage = ((total - input) * 100) / total;
  const inStepPercentage = ((from - to) * 100) / (from - to);
  const inStep = (percentage / inStepPercentage) * step;

  return inStep < 0 ? inStep * -1 : inStep;
}

window.addEventListener("scroll", () =>
  window.requestAnimationFrame(() => {
    const { top, bottom } = landing.getBoundingClientRect();

    const max = 0;
    const min = 5;

    const halfWindow = window.innerHeight / 2;
    const middle = top + landing.clientHeight;
    // ((input - min) * 100) / (max - min)
    const step =
      getStep(middle, windowInnerHeight - 100, 0, 5, 0.1).toFixed(3) * 100;

    landing.style.filter = `blur(${1 - step * -1.5 - 4}px)`;
    landing.style.opacity = (1 - step / 10) * 1.4;

    word.style.transform = `translateX(${20 - step * 10}px)`;
    definition.style.transform = `translateX(${20 + step * 10}px)`;
  })
);
